import * as Yup from "yup";

const passwordRegEx = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/;

export const signUpSchemaForSupplier = Yup.object({
  company_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your Company name"),
  user_name: Yup.string().min(2).max(25).required("Please Enter Your UserName"),
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string()
    .min(6)
    .required()
    .matches(
      passwordRegEx,
      "Password must include numeric, Uppercase, lowercase, special character",
    ),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

export const signUpSchemaForRetailer = Yup.object({
  firstname: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your First name"),
  lastname: Yup.string().min(2).max(25).required("Please enter your Last name"),
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string()
    .min(6)
    .required()
    .matches(
      passwordRegEx,
      "Password must include numeric, Uppercase, lowercase, special character",
    ),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

export const signInSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string()
    .min(6)
    .required()
    .matches(
      passwordRegEx,
      "Password must include numeric, Uppercase, lowercase, special character",
    ),
});

export const changePasswordInSchema = Yup.object({
  current_password: Yup.string()
    .min(6)
    .required("Current Password is required field")
    .matches(
      passwordRegEx,
      "Password must include numeric, Uppercase, lowercase, special character",
    ),
  new_password: Yup.string()
    .min(6)
    .required("New Password is required field")
    .matches(
      passwordRegEx,
      "New Password must include numeric, Uppercase, lowercase, special character",
    ),
  confirm_password: Yup.string()
    .required("Confirm Password is required field")
    .oneOf([Yup.ref("new_password"), null], "New Password must match"),
});

export const CompanyInfoFormSchemaSupplier = Yup.object({
  companyName: Yup.string().required("Please enter your Company name"),
  categories: Yup.string().required("Please select your categories"),
  location: Yup.string().required("Please select your location"),
  email: Yup.string().email().required("Please enter your email"),
  address: Yup.string().required("Please enter your address"),
  phone: Yup.string().required("Please enter your phone"),
  website: Yup.string().required("Please enter your website"),
  timezone: Yup.string().required("Please select your timezone"),
  companyType: Yup.string().required("Please select your company type"),
  ceoName: Yup.string().required("Please Enter your Ceo Name"),
  annualSales: Yup.string().required("Please Enter your Annual Sales"),

  annualRevenue: Yup.string().required("Please Select your Annual Revenue"),
  noOfEmployees: Yup.string().required("Please Select Number of Employees"),
  competitors: Yup.string().required("Please Enter Your competitors"),
});

export const AddEditProductSchema = Yup.object({
  // productName: Yup.string().required("Please enter your product name"),
  //productPrice: Yup.string().required("Please select your product price"),
  department: Yup.string().required("Please select department"),
  category: Yup.string().required("Please select category"),
  productDescription: Yup.string(),
  minOrderQuantity: Yup.string(),
  // MOQ: Yup.string().required("Please enter MOQ"),
  casePackDimension: Yup.string(),
  casePackWeight: Yup.string(),
  avgUnitSize: Yup.string(),
  avgUnitWeight: Yup.string(),
});

// Schema for editing a product (all fields optional)
export const EditProductSchema = Yup.object({
  productName: Yup.string().nullable(),
  //productPrice: Yup.string().required("Please select your product price"),
  department: Yup.string().nullable(),
  category: Yup.string().nullable(),
  productDescription: Yup.string().nullable(),
  minOrderQuantity: Yup.string().nullable(),
  // MOQ: Yup.string().required("Please enter MOQ"),
  casePackDimension: Yup.string().nullable(),
  casePackWeight: Yup.string().nullable(),
  avgUnitSize: Yup.string().nullable(),
  avgUnitWeight: Yup.string().nullable(),
});

export const MemberInfoFormSchemaSupplier = Yup.object({
  title: Yup.string().required("Please select  Title"),
  broker: Yup.string().required("Please select Broker"),
  location: Yup.string().required("Please select location"),
  address: Yup.string().required("Please enter  address"),
  phone: Yup.string().required("Please enter  phone"),
  email: Yup.string().email().required("Please enter email"),
  website: Yup.string().required("Please enter website"),
  timezone: Yup.string().required("Please select timezone"),
});

export const CategoryAndproductInfoFormSchemaSupplier = Yup.object({
  minOrderQuantity: Yup.string().required(
    "Please select  minimum order quantity",
  ),
  avgPrintPackShipTime: Yup.string().required(
    "Please select  average print,pack and ship time",
  ),
  pickupOption: Yup.string().required("Please select  pickup option"),
  details: Yup.string().required("Please enter any details"),
  pickupPoints: Yup.string().required("Please enter pickup points"),
  EDI: Yup.string().required("Please select  EDI"),
  allowanceProgram: Yup.string().required("Please select  allowance program"),
  deadNetProgram: Yup.string().required("Please select  dead net program"),
});

export const ArtworkInfoFormSchemaSupplier = Yup.object({
  artworkCapabilities: Yup.string().required(
    "Please select  artwork capabilities",
  ),
  // costPreference: Yup.string().required("Please select  cost preferance"),
  artworkRequirement: Yup.string().required(
    "Please select  artwork requirement",
  ),
  primaryContact: Yup.string().required("Please enter primary contact"),
  email: Yup.string().email().required("Please enter email"),
  agencyName: Yup.string().required("Please enter agency name"),
});

export const QualityAssuranceInfoFormSchemaSupplier = Yup.object({
  primaryContactQA: Yup.string().required("Please enter primary contact"),
  email: Yup.string().email().required("Please enter email"),
  lab: Yup.string().required("Please select lab work with"),
  sourcingpartners: Yup.array().min(
    1,
    "At least Sourcing Partners is required",
  ),
});

//Retailer create profile scheama start

export const CompanyInfoFormSchemaRetailer = Yup.object({
  companyName: Yup.string().required("Please enter your Company name"),
  businessType: Yup.string().required("Please select your business type"),
  location: Yup.string().required("Please select your headquarter location"),
  address: Yup.string().required("Please enter your address"),
  website: Yup.string().required("Please enter your website"),
  ceoName: Yup.string().required("Please Enter your Ceo Name"),
  annualSales: Yup.string().required("Please Enter your Annual Sales"),
  noOfLocations: Yup.string().required("Please Select Number of Locations"),
  annualRevenue: Yup.string().required("Please Select your Annual Revenue"),
  noOfEmployees: Yup.string().required("Please Select Number of Employees"),
  competitors: Yup.string().required("Please Enter Your competitors"),
});

export const MemberInfoFormSchemaRetailer = Yup.object({
  title: Yup.string().required("Please select  Title"),
  name: Yup.string().required("Please enter name"),
  phone: Yup.string().required("Please enter  phone"),
  email: Yup.string().email().required("Please enter email"),
  timezone: Yup.string().required("Please select timezone"),
  department: Yup.string().required("Please select department"),
});

const maxFileSizePrivatePortfolio = 1 * 1024 * 1024; // 5MB
export const CategoryAndproductInfoFormSchemaRetailer = Yup.object({
  minOrderQuantity: Yup.string().required(
    "Please select  minimum order quantity",
  ),
  avgPrintPackShipTime: Yup.string().required(
    "Please select  average print,pack and ship time",
  ),
  pickupOption: Yup.string().required("Please select  pickup option"),
  details: Yup.string().required("Please enter any details"),
  shipping: Yup.string().required("Please enter Shipping"),
  EDI: Yup.string().required("Please select  EDI"),
  allowanceProgram: Yup.string().required("Please select  allowance program"),
  deadNetProgram: Yup.string().required("Please select  dead net program"),
  dynamicFieldsPrivatePortfolio: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      file: Yup.mixed()
        .required("File is required")
        .test("file", "File is required", function () {
          const { file } = this.parent;
          return file.length > 0;
        })
        .test(
          "fileSize",
          "File size exceeds the maximum limit",
          function (value) {
            const { file } = this.parent;
            if (file.length > 0) {
              return value[0].size <= maxFileSizePrivatePortfolio;
            } else {
              return true;
            }
          },
        ),
    }),
  ),
});

export const ArtworkInfoFormSchemaRetailer = Yup.object({
  artworkCapabilities: Yup.string().required(
    "Please select  artwork capabilities",
  ),
  costPreference: Yup.string().required("Please select  cost preferance"),
  artworkRequirement: Yup.string().required(
    "Please select  artwork requirement",
  ),
  primaryContact: Yup.string().required("Please enter primary contact"),
  email: Yup.string().email().required("Please enter email"),
  agencyName: Yup.string().required("Please enter agency name"),
});

export const QualityAssuranceInfoFormSchemaRetailer = Yup.object({
  primaryContactQA: Yup.string().required("Please enter primary contact"),
  email: Yup.string().email().required("Please enter email"),
  lab: Yup.string().required("Please select lab work with"),
  sourcingpartners: Yup.array().min(
    1,
    "At least Sourcing Partners is required",
  ),
});

//Retailer create profile scheama end

//forgot password schema start
export const forgotPasswordSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
});

export const forgotPasswordOTPSchema = Yup.object({
  otp: Yup.string().required("Please enter otp received in email"),
});

export const forgotResetPasswordSchema = Yup.object({
  new_password: Yup.string()
    .min(7)
    .required("Please enter new password")
    .matches(
      passwordRegEx,
      "Requires 6+ chars, 1 lowercase, 1 uppercase, 1 number, 1 symbol",
    ),
  confirm_password: Yup.string()
    .required("Please enter confirm password")
    .oneOf(
      [Yup.ref("new_password"), null],
      "New password and confirm password must match",
    ),
});

//forgot password schema end

export const emailSchema = [
  {
    type: "email",
    required: false,
    whitespace: false,
    message: "Please enter valid email.",
  },
];

export const passwordSchema = [
  {
    required: true,
    message: "Please enter valid password.",
  },
  // {
  //   min: 6,
  //   message: "Please enter minimum 6 characters.",
  // },
  {
    pattern: passwordRegEx,
    min: 6,
    message:
      "Requires 6+ chars, 1 lowercase, 1 uppercase, 1 number, 1 symbol",
  },
];

export const confirmPasswordSchema = [
  {
    required: true,
    message: "Enter confirm password!",
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Password doesn't match"));
    },
  }),
];

export const companyNameSchema = [
  { required: false, message: "Please enter valid company name" },
  {
    min: 2,
    max: 25,
    message: "Company name should be minimum 2 and maximum 25 letter.",
  },
];

export const userNameSchema = [
  { required: true, message: "Please enter valid username!" },
  {
    min: 2,
    max: 25,
    message: "Username should be minimum 2 and maximum 25 letter.",
  },
];

export const passwordSchemaSignin = [
  {
    required: true,
    message: "Please enter password.",
  },

  {
    pattern: passwordRegEx,
    min: 6,
    message: "Entered password is not correct",
  },
];
