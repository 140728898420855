import {
  DownOutlined,
  MenuOutlined,
  MessageOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Drawer, Dropdown, Image, Input, Menu } from "antd";
import React, { useEffect, useMemo, useState } from "react";

//import logo from "./../../images/Navbar/NavbarGuest/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useWindowSize from "../../hooks/useWindowSize";
import { SearchUser } from "../../services/DashboardService";
import { logout } from "../../store/slice/auth_slice";
import { clearSelection } from "../../store/slice/flyout_slice";
import { clearUser } from "../../store/slice/user_slice";
import DepartmentFlyout from "../Layout/Navigations/DepartmentFlyout";
import dashboardIcon from "./../../images/Navbar/Navbar2/dashboard-light.png";
import myProductsIcon from "./../../images/Navbar/Navbar2/myproductsIcon-light.png";
import logo2 from "./../../images/Navbar/newlogo.png";
import classes from "./header.module.css";

// let userPlan = parseInt(localStorage.getItem("subscription"), 10) ?? 1;

const ChatOption = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const getToken = () => localStorage.getItem("token");

  const onChatOptionClick = () => {
    if (!getToken()) return;
    if (width < 768) {
      navigate("/app/chat");
    } else {
      window.open(
        "/app/chat",
        "_black",
        `popup=true,width=${window?.innerWidth - 400},height=${
          window?.innerHeight - 100
        },left=200,top=100`,
      );
    }
  };
  return (
    <div className="d-flex align-items-center" onClick={onChatOptionClick}>
      <MessageOutlined style={{ height: "100%", fontSize: "28px" }} />
      <span>Messaging</span>
    </div>
  );
};

const items = [
  { key: "1", label: <NavLink to="/app/myprofile">My Profile</NavLink> },
  { key: "2", label: <NavLink to="/app/products">My Products</NavLink> },
  {
    key: "3",
    label: <NavLink to="/app/settings">Settings & Subscription</NavLink>,
  },
  { key: "logout", label: "Logout" },
];

const dashboard = "/app/dashboard";
const department = "/app/users";
const products = "/app/products";
const chat = "/app/chat";

const urlsKey = {
  [dashboard]: "dashboard",
  [department]: "departments",
  [products]: "my-products",
  [chat]: "chat",
};

const Navbar = () => {
  const userObj = useSelector((state) => state.user.data);

  const username = userObj?.userName;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const windowSize = useWindowSize();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [current, setCurrent] = useState(urlsKey[location.pathname]);
  const [searchValue, setSearchValue] = useState("");
  const auth = useSelector((state) => state.auth);
  const isAuthenticated = auth.isAuthenticated;
  const [userPlan, setUserPlan] = useState(null);
  const [loadingPlan, setLoadingPlan] = useState(true);

  useEffect(() => {
    setCurrent(urlsKey[location.pathname]);
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname !== "/app/users") {
      setSearchValue("");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (userObj) {
      const plan = parseInt(localStorage.getItem("subscription"), 10);
      setUserPlan(plan);
      setLoadingPlan(false);
    }
  }, [userObj]);

  const messageOption =
    !loadingPlan && (userPlan === 2 || userPlan === 3)
      ? {
          key: urlsKey[chat],
          label: <ChatOption />,
        }
      : null;

  const navBarMenu = [
    {
      key: urlsKey[department],
      label: <DepartmentFlyout />,
    },
    {
      key: urlsKey[dashboard],
      label: (
        <NavLink className="d-flex align-items-center" to={dashboard}>
          <Image src={dashboardIcon} alt="dashboard-icon" preview={false} />
          <span>Dashboard</span>
        </NavLink>
      ),
    },
    {
      key: urlsKey[products],
      label: (
        <NavLink className="d-flex align-items-center" to={products}>
          <Image src={myProductsIcon} alt="product-icon" preview={false} />
          <span>My Products</span>
        </NavLink>
      ),
    },
    messageOption,
  ].filter(Boolean);

  const handleEnterSearch = async () => {
    // Ensure the input value isn't empty
    const searchStr = searchValue.trim();
    if (searchStr) {
      const searchDTO = {
        key: searchStr,
        forNetwork: false,
        pageNumber: 1,
        userId: userObj?.id,
      };

      try {
        await SearchUser(searchDTO, true);

        dispatch(clearSelection());
        setDrawerOpen(false);
        navigate("/app/users", { state: searchStr });
      } catch (error) {
        console.error("Error occurred during search:", error);
      }
    }
  };

  // const handleEnterSearch = (event) => {
  //   event.preventDefault();
  //   const value = searchValue.trim();
  //   if (value) {
  //     dispatch(clearSelection());
  //     setDrawerOpen(false);
  //     navigate("/app/users", { state: value });
  //   }
  // };

  const onClick = ({ key }) => {
    setDrawerOpen(false);
    if (key === "logout") {
      dispatch(logout());
      dispatch(clearUser());
      toast.success("Logout Successfully");
      navigate("/public/signin");
    }
  };

  const menu = useMemo(
    () => (
      <>
        <Menu
          theme="light"
          mode={windowSize.width > 640 ? "horizontal" : "vertical"}
          items={navBarMenu}
          selectedKeys={[current]}
          forceSubMenuRender={true}
          className={classes.menu}
          onSelect={({ key }) => {
            if (key !== "departments") setDrawerOpen(false);
          }}
        />
        <Input
          prefix={<SearchOutlined />}
          key="global-search"
          allowClear
          size="large"
          placeholder="Search here..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onPressEnter={handleEnterSearch}
          style={{ marginRight: "18px", maxWidth: "350px" }}
        />
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [current, windowSize.width, searchValue], // Include searchValue as a dependency
  );

  const userInfo = isAuthenticated ? (
    <Dropdown
      menu={{ items, onClick }}
      trigger={["click"]}
      placement="bottomRight"
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      overlayStyle={{ zIndex: 99999 }}
    >
      <div className={classes["user-detail"]}>
        <Avatar
          src={userObj?.profilePath}
          alt="profile-logo"
          preview={false}
          style={{ width: "40px", height: "40px" }}
        />
        <div className={classes["user-detail-name"]}>
          <span>{username}</span>
          <span className={classes["user-detail-name-role"]}>
            {userObj?.user_type
              ?.toLowerCase()
              .replace(/^\w/, (c) => c.toUpperCase())}
          </span>
        </div>
        <DownOutlined />
      </div>
    </Dropdown>
  ) : (
    <div>
      <Button
        type="primary"
        className={classes["loginBtn"]}
        onClick={() => {
          setDrawerOpen(false);
          navigate("/public/signin");
        }}
      >
        {"Sign In"}
      </Button>
      <Button
        type="primary"
        className={classes["signUpBtn"]}
        onClick={() => {
          setDrawerOpen(false);
          navigate("/public/signup");
        }}
      >
        {"Sign Up"}
      </Button>
    </div>
  );

  return (
    <div className={classes.container}>
      <div className={classes.topNav}>
        <div className={classes["img-nav-container"]}>
          <Link to="/">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                src={logo2}
                alt="PriBr Logo"
                style={{
                  width: "58px",
                  height: "8ppx",
                }}
              />
              <div className={classes.logoText}>
                Pri<span>Br</span>
              </div>
            </div>
          </Link>
          {windowSize.width > 640 && isAuthenticated ? menu : null}
        </div>

        {windowSize.width < 641 && isAuthenticated ? (
          <>
            <MenuOutlined
              style={{ fontSize: "26px", padding: "10px", color: "white" }}
              onClick={() => setDrawerOpen(true)}
            />
            <Drawer
              placement="left"
              closable={true}
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              footer={userInfo}
              styles={{ footer: { padding: "4px" } }}
            >
              {isAuthenticated ? (
                <Menu
                  className="drawer-menu" // Add this class
                  theme="light"
                  mode="inline" // Use "inline" for vertical menus
                  items={navBarMenu}
                  selectedKeys={[current]}
                  forceSubMenuRender={true}
                  onSelect={({ key }) => {
                    if (key !== "departments") setDrawerOpen(false);
                  }}
                />
              ) : null}
            </Drawer>
          </>
        ) : (
          userInfo
        )}
      </div>
    </div>
  );
};

export default React.memo(Navbar);
